<template>
  <div ref="modalContainer"></div>
  <a-card class="content-wrapper">
    <template #title>
      <CardHeader></CardHeader>
    </template>
    <div class="body-content">
      <a-form layout="inline">
        <a-form-item label="车牌号">
          <a-input
            v-model:value="params.plateNumber"
            placeholder="输入车牌号"
            allow-clear
          ></a-input>
        </a-form-item>

        <a-form-item style="padding-bottom: 10px" label="车牌颜色">
          <a-select
            v-model:value="params.plateColor"
            notFoundContent="暂无数据"
            style="width: 200px"
            placeholder="全部"
            allow-clear
            :options="plateColors"
          ></a-select>
        </a-form-item>

        <a-form-item label="手机号码">
          <a-input
            v-model:value="params.phone"
            placeholder="输入用户手机号"
            allow-clear
          ></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="doQuery">
            <template #icon>
              <SearchOutlined />
            </template>
            查询
          </a-button>
        </a-form-item>

        <a-form-item>
          <a-button
            perm="member:whitePlateNumber:add"
            class="btn-success"
            @click="showAddModal"
          >
            <template #icon>
              <PlusCircleOutlined />
            </template>
            添加
          </a-button>
        </a-form-item>
      </a-form>
      <div style="padding: 4px 0">
        <a-table
          row-key="id"
          table-layout="fixed"
          :data-source="dataList.list"
          :columns="columns"
          :loading="loading"
          :pagination="false"
          bordered
          :scroll="{ y: 500 }"
        >
          <template #operation="{ record }">
            <TipButton
              perm="member:whitePlateNumber:del"
              btn-class="btn-danger"
              title="删除"
              @btnClick="delItem(record.id)"
            >
              <template #default>
                <DeleteOutlined />
              </template>
            </TipButton>
          </template>
          <template #plateNumber="{ record }">
            <a-tag :color="plateColorStyle(record.plateColor)">
              <span
                :style="{
                  color: record.plateColor == 4 ? '#001510' : '#FFFFFF',
                }"
                >{{ record.plateNumber }}</span
              ></a-tag
            >
          </template>
        </a-table>
      </div>
      <a-pagination
        v-model:current="page"
        v-model:page-size="pageSize"
        :total="total"
        :show-total="total => `${total}条数据`"
        @change="currentChange"
      >
      </a-pagination>
    </div>
  </a-card>

  <add
    v-if="addShow"
    :modal-container="$refs.modalContainer"
    @close="hideAddModal"
  ></add>
</template>

<script>
import CardHeader from "@/components/CardHeader";
import useWhitePlateNumber from "@/hooks/member/useWhitePlateNumber";
import usePagination from "@/hooks/usePagination";
import useAddExit from "@/hooks/useAddExit";
import { onMounted /*, ref*/ } from "vue";
import Add from "@/views/Member/WhitePlateNumber/components/Add";
import { delSpecialPlate } from "@/api/member/whitePlateNumberApi";
import TipButton from "@/components/TipButton";
import useMessage from "@/hooks/useMessage";

export default {
  name: "index",
  components: {
    Add,
    CardHeader,
    TipButton,
  },
  setup() {
    const { params, dataList, total, plateColors, loading, columns, loadData } =
      useWhitePlateNumber();

    const { page, pageSize, currentChange, doQuery, doRefresh } =
      usePagination(loadData);

    const { addShow, showAddModal, hideAddModal } = useAddExit(doQuery);
    const { showConfirm, showLoading, closeLoading, showTipMessage, showErr } =
      useMessage();
    const plateColorStyle = val => {
      switch (val) {
        case 0:
            return "#888888";
        case 1:
            return "#0000FF";
        case 2:
            return "#FFA500";
        case 3:
            return "#000000";
        case 4:
            return "#FFFFFF";
        case 5:
            return "#008000";
        default:
            return "#FF0000";
      }
    };

    onMounted(() => {
      loadData(page.value, pageSize.value);
    });
    const delItem = async id => {
      let action = await showConfirm(
        "删除该车辆后无法享受特殊车辆优惠，请确认后删除?",
      );
      if (action) {
        await showLoading("正在删除，请稍后...");
        try {
          await delSpecialPlate(id);
          await closeLoading();
          await showTipMessage("删除成功", "success");
          doQuery();
        } catch (e) {
          await closeLoading();
          await showErr(e);
        }
      }
    };
    return {
      params,
      page,
      pageSize,
      total,
      loading,
      currentChange,
      dataList,
      doQuery,
      doRefresh,
      columns,
      addShow,
      showAddModal,
      hideAddModal,
      delItem,
      plateColorStyle,
      plateColors,
    };
  },
};
</script>

<style scoped></style>
